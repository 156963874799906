// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #ffca22;
  --ion-color-primary-rgb: 255, 202, 34;
  --ion-color-primary-contrast: #431e05;
  --ion-color-primary-contrast-rgb: 67, 30, 5;
  --ion-color-primary-shade: #e0b21e;
  --ion-color-primary-tint: #ffcf38;

  --ion-color-secondary: #431e05;
  --ion-color-secondary-rgb: 67, 30, 5;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3b1a04;
  --ion-color-secondary-tint: #56351e;

  --ion-color-tertiary: #6030ff;
  --ion-color-tertiary-rgb: 96, 48, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #542ae0;
  --ion-color-tertiary-tint: #7045ff;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #dff5e5;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e9c8cb;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #464646;
  --ion-color-medium-rgb: 70, 70, 70;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #e2e2e2;
  --ion-color-medium-tint: #595959;

  --ion-color-dark: #0c111d;
  --ion-color-dark-rgb: 12, 17, 29;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #0b0f1a;
  --ion-color-dark-tint: #242934;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  --ion-color-bg: #fff6df;
  --ion-color-bg-rgb: 252, 236, 192;
  --ion-color-bg-contrast: #000000;
  --ion-color-bg-contrast-rgb: 0, 0, 0;
  --ion-color-bg-shade: #ded0a9;
  --ion-color-bg-tint: #faefd1;

  --main-bg: #eff2f5;
  --input-bg: #fff;
  --ion-text-color: #0c111d;

  --border-radius: 8px;

  // --ion-background-color: #f1f3f9;
}

@use 'node_modules/@ngxpert/hot-toast/src/styles/styles.scss';

@import '@fontsource/inter/200.css';
@import '@fontsource/inter/300.css';
@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/700.css';
@import '@fontsource/inter/800.css';
@import '@fontsource/inter/900.css';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

.alert-translucent.sc-ion-alert-ios-h .alert-wrapper.sc-ion-alert-ios {
  background: rgba(var(--ion-background-color-rgb, 255, 255, 255), 0.6);
  backdrop-filter: saturate(180%) blur(10px);
}

@tailwind base;

@layer base {
  html {
    @apply text-gray-800;
  }
}
@tailwind components;
@tailwind utilities;

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  --ion-font-family: 'Inter';
}

body {
  font-family: 'Inter', sans-serif;
  @apply leading-tight;

  --background: #eff2f5;
  // --background: linear-gradient(0deg, rgb(255, 255, 255) 0%, var(--ion-color-bg-tint) 100%);

  /*background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    var(--ion-color-bg-tint) 100%
  );*/

  .collapse-title,
  :where(.collapse > input[type='checkbox']),
  :where(.collapse > input[type='radio']) {
    min-height: unset !important;
  }
}

ion-header {
  box-shadow: none;
}

body {
  ion-item,
  ion-list,
  .list-ios {
    --background: transparent;
    background: transparent;
    opacity: 1;
  }
  ion-list {
    ion-item {
      --border-color: #d9dce0;
    }

    ion-item:last-of-type {
      --border-color: transparent;
    }
  }

  ion-item {
    --background-hover-opacity: 0;
    &.no-space {
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-start: 0px;
      --inner-padding-end: 0px;
    }

    h2 {
      font-weight: 500;
      font-size: 15px;
    }
    ion-checkbox {
      flex: none !important;
    }
    ion-icon {
      // padding: 10px;
      // height: 20px;
      // width: 20px;
      //  border-radius: 50%;
      &.ion-icon-primary {
        color: var(--ion-color-primary);
      }
      &.ion-icon-danger {
        color: var(--ion-color-danger);
      }
      &.ion-icon-success {
        color: var(--ion-color-success);
      }
      &.ion-icon-medium {
        color: var(--ion-color-medium);
      }
    }
    /*--background-hover-opacity: 0;
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-end: 0px;

    --padding-top: 9px;
    --padding-bottom: 9px;

    ion-icon {
      padding: 10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      &.ion-icon-primary {
        background: var(--ion-color-bg);
        color: var(--ion-color-primary);
      }
      &.ion-icon-danger {
        background: var(--ion-color-danger-shade);
        color: var(--ion-color-danger);
      }
      &.ion-icon-success {
        background: var(--ion-color-success-shade);
        color: var(--ion-color-success);
      }
      &.ion-icon-medium {
        background: var(--ion-color-medium-shade);
        color: var(--ion-color-medium);
      }
    }
    h2 {
      font-weight: 500 !important;
    }*/

    &[disabled] {
      ion-label {
        opacity: 0.6;
      }
      ion-input,
      ion-select {
        opacity: 0.6 !important;
      }
    }

    &::part(native) {
      position: unset;
      .item-inner {
        position: unset;
      }
    }
    ion-label {
      font-weight: 600;
    }
    ion-select {
      border: 1px solid #dedede;
      border-radius: 4px;
    }
    ion-input,
    ion-select {
      --padding-start: 10px !important;
      --inner-padding-start: 10px !important;
      --border-radius: 4px;
      border-radius: 4px;
      --background: var(--input-bg) !important;
      background: var(--input-bg) !important;
      padding: 0 10px !important;
      // box-shadow: inset 0 0 0 1px #d6d6d6;
      .input-wrapper,
      .select-wrapper {
        border: 1px solid #dedede;
        border-radius: 4px;
      }
      &.has-focus {
        background: #fffcf3 !important;
        --background: #fffcf3 !important;

        .input-wrapper,
        .select-wrapper {
          border-radius: 4px;
          border: 1px solid var(--ion-color-primary) !important;
          // box-shadow: inset 0 0 0 1px var(--ion-color-primary);
        }
      }
    }
    &.item-has-focus {
      ion-input,
      ion-select {
        transition: all 0.1s ease-in-out;
        box-shadow: inset 0 0 0 1px var(--ion-color-primary);
      }
    }
  }
}

.btn {
  box-shadow: 0 0 transparent;
  //border: 1px solid var(--ion-color-medium-tint);
}

ion-content {
  // --background: var(--main-bg);
  // --background: linear-gradient(0deg, rgb(255, 255, 255) 0%, var(--ion-color-bg-tint) 100%);

  --background: #eff2f5;
}
.header-collapse-condense ion-toolbar {
  --background: transparent;
}

ion-toolbar {
  --padding-start: 1rem;
  --padding-end: 0.8rem;
  --background: transparent;
  ion-title[size='large'] {
    // padding-inline-start: 0;
  }
  ion-title {
    //--padding-start: 0;
    //padding-inline-start: 0;
    @apply text-[#0C111D];
  }
  ion-back-button {
    --color: var(--ion-color-dark);
    //--padding-start: 7px;
  }
}

ion-input,
ion-select {
  --color: #fff;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;

  input {
    width: 100%;
    border: none !important;
    outline: none !important;
    background: transparent !important;
  }
}

.page-inner {
  //@apply px-7 pb-10 pt-7;
  @apply px-5 py-5 container mx-auto;
}

swiper-container {
  --swiper-pagination-color: var(--ion-color-primary);
}

.label {
  font-size: 0.875rem;
  font-weight: 600;
}

.card {
  @apply rounded-lg bg-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.solakon-card {
  @apply bg-white
  rounded-lg
  h-full
  flex flex-row
  items-center
  justify-center
  gap-4
  py-3
  px-5 relative;
  // border: 1px solid #dedede;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.sk-skeleton-wrapper {
  ion-item {
    ion-skeleton-text {
      width: calc(100% + 15px);
      left: -15px;
    }
  }
  ion-item {
    ion-input {
      ion-skeleton-text {
        width: 100%;
        left: 0;
      }
    }
  }
  ion-skeleton-text {
    @apply transition-all duration-300 rounded;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    opacity: 1;
    backdrop-filter: blur(14px);
  }
}
ion-item {
  ion-skeleton-text {
    height: 100%;
    width: calc(100% + 15px);
    position: absolute;
    top: 0;
    left: -15px;
    z-index: 10;
    padding: 0;
    margin: 0;
    opacity: 1;
    backdrop-filter: blur(14px);
  }
  ion-label {
    color: #000;
    --color: #000 !important;
  }
}

ion-footer {
  // padding-bottom: var(--ion-safe-area-bottom, 0);

  ion-toolbar {
    background: #fff;
    border-radius: 33px 33px 0px 0px;
    box-shadow: -1px 0 10px rgba(0, 0, 0, 0.05);
  }
}

body {
  .item-interactive-disabled.sc-ion-label-ios-h:not(.item-multiple-inputs),
  .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h {
    //  opacity: 0.8;
  }
}

.chartjs-tooltip {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: white;
  opacity: 1;
  pointer-events: none;
  top: 100px !important;
  left: 50% !important;
  position: fixed;
  transform: translate(-50%, 0px);
  transition: all 0.1s ease 0s;
  padding: 10px 20px;
  text-align: left;
}

/*
.ios {
  ion-header {
    padding-top: 0;
    --ion-safe-area-top: 0;
    --ion-safe-area-bottom: 20px;
  }
  ion-toolbar > div {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    --ion-safe-area-top: 2px;
  }
}*/

input,
textarea {
  caret-color: var(--ion-color-secondary) !important;
}

canvas.confetti {
  display: block;
  z-index: 1000000;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.header-gradient {
  @apply absolute w-full z-30 top-0 left-0 pointer-events-none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);

  height: 50vh;
}

.shadow-custom {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}

.solakon-ios {
  .settings-bar {
    top: 3.5rem;
  }
}
.solakon-md {
  .settings-bar {
    top: 20px;
  }
}
/*
.plt-android {
  .bottom-box {
    margin-bottom: var(--safe-area-inset-bottom);
  }
  ion-footer.footer-toolbar-padding ion-toolbar:last-of-type,
  ion-tab-bar,
  ion-modal ion-content,
  .cupertino-pane {
    padding-bottom: var(--safe-area-inset-bottom);
  }

  ion-action-sheet {
    margin-bottom: var(--safe-area-inset-bottom);
  }

  .settings-bar,
  ion-app {
    padding-top: var(--safe-area-inset-top);
  }
}*/

.confetti {
  right: 0;
  top: 0;
  transform: translate(0, 0);
  font-size: 200px;
  opacity: 0.2;
  filter: invert(100%);
  z-index: 0;
  position: fixed;
}

.timeline-badge {
  @apply text-base font-bold rounded-full border align-middle text-white bg-primary-400 min-w-8 min-h-8 flex justify-center items-center;
}

ion-action-sheet {
  --button-color: var(--ion-color-dark) !important;
}
ion-refresher.refresher-native {
  z-index: 100;
}
ion-refresher.refresher-native ion-spinner,
button.alert-button.sc-ion-alert-ios:not(.alert-button-role-destructive),
ion-toast::part(button) {
  color: var(--ion-color-dark);
  --color: var(--ion-color-dark);
}

ion-alert {
  @apply transition-all transform-gpu duration-500;
}
ion-toast::part(container) {
  @apply shadow-xl rounded-2xl;
}
[hidden] {
  display: none !important;
}

.fullscreen-overlay {
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--ion-safe-area-top, 0);

  .close-button {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    padding-top: var(--ion-safe-area-top, 0);
    ion-button {
      --color: white;
    }
  }

  .fullscreen-backdrop {
    overflow: hidden;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: -1;
    backdrop-filter: saturate(180%) blur(10px);
    transition: 300ms;
    background-color: rgba(0, 0, 0, 0.6);
    display: block;
  }
}

.fullscreen-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.fullscreen-modal::part(content) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.d-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
}

ion-item-options {
  border-bottom-width: 0 !important;
}
ion-button.fullscreen {
  --background: white;
  --color: black;
}

#add-battery-modal {
  --max-width: 300px;
  --max-height: 450px;
  --border-radius: 20px;
}

#energy-info-modal {
  --max-width: 300px;
  --max-height: 330px;
  --border-radius: 20px;
}

#sure-modal {
  --max-width: 300px;
  --max-height: 450px;
  --border-radius: 20px;
}

#firmware-modal {
  --max-width: 300px;
  --max-height: 420px;
  --border-radius: 20px;
}

ion-list.solakon-list {
  ion-item {
    --background-hover-opacity: 0;
    --padding-start: 12px;
    --padding-end: 0px;
    --inner-padding-start: 0;
    --inner-padding-end: 12px;

    --padding-top: 0px;
    --padding-bottom: 0px;

    h2 {
      font-size: 14px;
    }

    ion-badge.badge {
      color: #2dd55b;
      background: #2dd55b;
    }

    ion-select {
      border: none !important;
      padding: 0 !important;
    }
  }
}

.btn {
  &.btn-outline:not(.btn-circle) {
    box-shadow: inset 0 0 0 1px #d0d5dd;
    background: transparent;
    color: #000;
    // border: 1px solid #d0d5dd;
  }
}
.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.4);
  backdrop-filter: saturate(180%) blur(20px);
  transition: background-color 0.2s ease;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply flex flex-col items-center justify-center col-span-2 py-32 gap-4;
  img.logo {
    padding-top: 0;
    --ion-safe-area-top: 0;
    --ion-safe-area-bottom: 20px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    --ion-safe-area-top: 2px;
  }
  ion-skeleton-text {
    @apply transition-all duration-300;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    opacity: 1;
    backdrop-filter: blur(14px);
  }
}

ion-modal.custom-scroll ion-content::part(scroll) {
  overflow-y: scroll;
  overscroll-behavior-y: contain;
}
